<template>
    <modal :open="modalOpen" @close="$emit('closeModal');" :loading="modalLoading" @save="savePrefernce" :modalData="modalData">
        <template v-slot:modalContent>
            <v-row class="pa-0 ma-0">
                <v-col cols="12">
                    <v-subheader class="primary--text pa-0 ma-0 text-h6 mb-1 text-capitalize">
                        Receipt Preference
                        <v-btn class="mx-2 secondary" :disabled="picklistLoading || receiptLoading" x-small icon dark @click="getListDatas('receipt')">
                            <v-icon dark size="15" v-if="!receiptLoading">mdi-sync</v-icon>
                            <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                        </v-btn>
                    </v-subheader>
                    <v-row class="pa-0 ma-0 mb-5">
                        <div class="skeleton-list-item w-50" v-if="picklistLoading || receiptLoading">
                            <v-skeleton-loader :loading="true" type="list-item"></v-skeleton-loader>
                        </div>
                        <template v-if="!receiptLoading && !picklistLoading">
                            <v-checkbox v-for="(item, index) in Receipt_Preference__c" hide-details="auto" :input-value="model.receipt_preference__c.includes(item.value)" :key="index"
                                @change="receiptChange($event, item.value)" class="ma-0 mr-4" :label="item.text" color="primary" />
                        </template>
                    </v-row>
                    <v-row class="pa-0 ma-0 align-center mt-3">
                        <v-subheader class="primary--text pa-0 ma-0 text-h6 font-weight-bold text-capitalize">opt out of all communication</v-subheader>
                        <v-switch label="" color="primary" hide-details="auto" class="ml-5 pa-0 ma-0" v-model="model.global_opt_out__c"
                            :false-value="0" :true-value="1"></v-switch>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 mb-3 text-h6 font-weight-bold text-capitalize">
                                Email Opt Out
                                <v-btn class="mx-2 secondary"  :disabled="picklistLoading || emailLoading" x-small icon dark @click="getListDatas('email')">
                                    <v-icon dark size="15" v-if="!emailLoading">mdi-sync</v-icon>
                                    <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                                </v-btn>
                            </v-subheader>
                            <div class="skeleton-list-item w-full" v-if="picklistLoading || emailLoading">
                                <v-skeleton-loader :loading="true" type="list-item"></v-skeleton-loader>
                            </div>
                            <v-autocomplete filled rounded prepend-inner-icon="mdi-account-supervisor" v-if="!picklistLoading && !emailLoading"
                                ref="email_opt_out" multiple :menu-props="{ closeOnClick: true}" v-model="model.email_opt_out__c"
                                hide-details="auto" label="Select Email Opt Out" :items="Email_Opt_Out__c"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 mb-3 text-h6 font-weight-bold text-capitalize">
                                Mail Opt Out
                                <v-btn class="mx-2 secondary" :disabled="picklistLoading || mailLoading" x-small icon dark @click="getListDatas('mail')">
                                    <v-icon dark size="15" v-if="!mailLoading">mdi-sync</v-icon>
                                    <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                                </v-btn>
                            </v-subheader>
                            <div class="skeleton-list-item w-full" v-if="picklistLoading || mailLoading">
                                <v-skeleton-loader :loading="true" type="list-item"></v-skeleton-loader>
                            </div>
                            <v-autocomplete filled rounded prepend-inner-icon="mdi-account-supervisor" v-if="!mailLoading && !picklistLoading"
                                ref="mail_opt_out" multiple :menu-props="{ closeOnClick: true}" v-model="model.direct_mail_opt_out__c"
                                hide-details="auto" label="Select Mail Opt Out" :items="Direct_Mail_Opt_Out__c"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 mb-3 text-h6 font-weight-bold text-capitalize">
                                SMS Opt Out
                                <v-btn class="mx-2 secondary" :disabled="picklistLoading || smsLoading" x-small icon dark @click="getListDatas('sms')">
                                    <v-icon dark size="15" v-if="!smsLoading">mdi-sync</v-icon>
                                    <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                                </v-btn>
                            </v-subheader>
                            <div class="skeleton-list-item w-full" v-if="picklistLoading || smsLoading">
                                <v-skeleton-loader :loading="true" type="list-item"></v-skeleton-loader>
                            </div>
                            <v-autocomplete filled rounded prepend-inner-icon="mdi-account-supervisor"  v-if="!picklistLoading && !smsLoading"
                                ref="sms_opt_out" multiple :menu-props="{ closeOnClick: true}" v-model="model.sms_opt_out__c"
                                hide-details="auto" label="Select SMS Opt Out" :items="SMS_Opt_Out__c" 
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 mb-3 text-h6 font-weight-bold text-capitalize">
                                Phone Opt Out
                                <v-btn class="mx-2 secondary" :disabled="picklistLoading || phoneLoading" x-small icon dark @click="getListDatas('phone')">
                                    <v-icon dark size="15" v-if="!phoneLoading">mdi-sync</v-icon>
                                    <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                                </v-btn>
                            </v-subheader>
                            <div class="skeleton-list-item w-full" v-if="picklistLoading || phoneLoading">
                                <v-skeleton-loader :loading="true" type="list-item"></v-skeleton-loader>
                            </div>
                            <v-autocomplete filled rounded prepend-inner-icon="mdi-account-supervisor"  v-if="!picklistLoading && !phoneLoading"
                                ref="phone_opt_out" multiple :menu-props="{ closeOnClick: true}" v-model="model.phone_opt_out__c"
                                hide-details="auto" label="Select Phone Opt Out" :items="Phone_Opt_Out__c" 
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    props:['modalOpen'],
    emits: ['closeModal'],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data: (vm) => ({
        modalLoading: false,
        modalData: {
            title: 'Communication Preferences',
            saveBtnTxt: 'Save',
            width: 1200,
            height: '500px'
        },
        receiptModel: [],
        Receipt_Preference__c: [],
        Email_Opt_Out__c: [],
        Direct_Mail_Opt_Out__c: [],
        SMS_Opt_Out__c: [],
        Phone_Opt_Out__c: [],
        receiptLoading: false,
        smsLoading: false,
        emailLoading: false,
        mailLoading: false,
        phoneLoading: false,
        picklistLoading: false
    }),
    computed: {        
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("setModel", newValue);
            },
        },
    },
    created() {
        this.getListDatas();
        if(!this.model.receipt_preference__c) this.model.receipt_preference__c = [];
    },
    methods: {
        ...mapActions(["getSearchPicklist"]),

        getListDatas(type) {
            let reqData = [];
            const reqObject = {
                receipt: {object: "Contact", field: 'Receipt_Preference__c'},
                email: {object: "Contact", field: 'Email_Opt_Out__c'},
                mail: {object: "Contact", field: 'Direct_Mail_Opt_Out__c'},
                sms: {object: "Contact", field: 'SMS_Opt_Out__c'},
                phone: {object: "Contact", field: 'Phone_Opt_Out__c'}
            };
            if (!type) {
                this.picklistLoading = true;
                reqData = reqObject;
            } else {
                this[`${type}Loading`] = true;
                reqData.push({...reqObject[type], ...{force: 1}});
            }
            this.getSearchPicklist(reqData).then((response) => {
                if (!_.isEmpty(response)) {
                    Object.keys(response).forEach(obj => {
                        this[obj] = response[obj];
                    });
                }
                if (type ) this[`${type}Loading`] = false;
                else this.picklistLoading= false;
            }).catch(err => {
                if (type ) this[`${type}Loading`] = false;
                else this.picklistLoading= false;
            });
        },
        receiptChange(evt, value) {
            if (evt && this.model.receipt_preference__c[0] != value) {
                this.model.receipt_preference__c = [value];
            } else {
                this.model.receipt_preference__c = [];
            }
        },
        savePrefernce() {
            this.$emit('closeModal');
        },
    }
}
</script>
